@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-behavior: smooth;
    box-sizing: border-box;
    user-select: none;
}

/* Hide scrollbar */
.hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0.5rem; /* Adjust as needed */
    background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background: transparent;
}
